<template>
  <div class="question-text mt-2 mb-2">{{ question.question_text }}</div>
  <div class="text-[14px] text-[#939393] mb-10 subtitle">
    <div v-if="question.maxSelection">Select up to {{ question.maxSelection }} that apply</div>
    <div v-else>Choose all that apply</div>
  </div>
  <div class="grid options-layout items-center w-full">
    <template v-for="(option, i) in question.options" :key="i">
      <div
        class="question-choice flex items-center justify-center"
        :class="{
          'selected-option': isSelected(option.option_id),
          'cursor-pointer': store.ifEditable(),
          hidden: option.option_content === 'None of the above',
        }"
        @click="select(option.option_id)"
      >
        {{ option.option_content }}
        <img v-if="isSelected(option.option_id)" :src="checkmark" class="checkmark" />
      </div>
    </template>
  </div>
</template>

<script>
import checkmark from "@/assets/checkmark.svg";
import { store } from "../../service/storeV2.js";
export default {
  name: "Single Choice",
  props: {
    question: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      store,
      checkmark: checkmark,
    };
  },
  methods: {
    select(option_id) {
      if (this.store.ifEditable()) {
        const { question_id } = this.question;
        let oldAnswers = this.store.answers[question_id].option_id;
        if (oldAnswers.includes(option_id)) {
          //deselect
          this.store.deleteOption(question_id, option_id);
          this.getWarningMsg();
        } else {
          //select
          if (this.question.maxSelection && oldAnswers.length >= this.question.maxSelection) {
            //do nothing
          } else {
            //   const noneOption = this.question.choices.findIndex(c =>c==="None")
            //  oldAnswers = oldAnswers.filter((id) => Number(id)!== this.question.option_id[noneOption]);
            if (this.question?.none_id)
              oldAnswers = oldAnswers.filter((id) => Number(id) !== this.question.none_id);
            oldAnswers = [...oldAnswers, option_id];
            this.store.answers[question_id].option_id = [...oldAnswers];
            this.getWarningMsg();
          }
        }
      }
    },
    isSelected(i) {
      return this.store.answers[this.question.question_id]?.option_id.includes(i);
    },
    getWarningMsg() {
      let msg = "";
      if (this.store.answers[this.question.question_id].option_id.length > 0) {
        this.question.options.some((option) => {
          if (
            option.warning &&
            this.store.answers[this.question.question_id].option_id.includes(option.option_id)
          ) {
            msg = option.warning;
            return true;
          }
          return false;
        });
      }
      this.$emit("updateWarning", msg);
    },
  },
  emits: ["updateWarning","terminateSurvey"],
  mounted() {
    this.getWarningMsg();
  },
};
</script>

<style>
.selected-option {
  background: #f8fafb;
  border: 1px solid #3498db;
}

.checkmark {
  position: absolute;
  right: 29px;
  bottom: 18px;
}

.nutripro .subtitle {
  text-align: center;
}

@media all and (max-width: 768px) {
  .skincare .subtitle {
    text-align: center;
  }
  .skincare-followup .subtitle {
    text-align: center;
  }
}
</style>
