<template>
  <div class="question-text mt-2 mb-7">{{ question.question }}</div>
  <div class="grid options-layout items-center w-full">
    <div
      v-for="(choice, i) in question.choices"
      :key="i"
      class="question-choice flex items-center justify-center"
      :class="{ 'selected-option': isSelected(i), 'cursor-pointer': store.ifEditable() }"
      @click="select(i)"
      :data-cy="question.option_id[i]"
    >
      {{ choice }}
      <img v-if="isSelected(i)" :src="checkmark" class="checkmark" />
    </div>
  </div>
</template>

<script>
import checkmark from "../assets/checkmark.svg";
import { store } from "../service/store.js";
export default {
  name: "Single Choice",
  props: {
    question: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      store,
      checkmark: checkmark,
    };
  },
  methods: {
    select(i) {
      if (this.store.ifEditable())
        this.store.answers[this.question.question_id].option_id = this.question.option_id[i];
    },
    isSelected(i) {
      return this.question.option_id[i] === this.store.answers[this.question.question_id]?.option_id;
    },
  },
};
</script>

<style>
.question-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

.nutripro .question-text {
  text-align: center;
}

.question-choice {
  border-radius: 5px;
  line-height: 20px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  width: 100%;
}

.nutripro .options-layout {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 20px;
  row-gap: 24px;
  justify-content: center;
}

.skincare .options-layout {
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  column-gap: 20px;
  row-gap: 24px;
}

.skincare-followup .options-layout {
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  column-gap: 20px;
  row-gap: 24px;
}

@media all and (max-width: 360px) {
  .skincare .options-layout {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .skincare-followup .options-layout {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.nutripro .question-choice {
  border: 1px solid #979797;
  padding: 14px 10%;
  max-width: 435px;
  margin: 0 auto;
}

.nutripro .selected-option {
  background: #f8fafb;
  border: 1px solid #3498db;
}

.skincare .question-choice {
  border: 1px solid #ffd700;
  padding: 10px 0;
  text-align: center;
  height: auto;
}
.skincare .selected-option {
  background-color: black;
  color: #ffd700;
  border: 1px solid black;
}

.skincare-followup .question-choice {
  border: 1px solid #ffd700;
  padding: 10px 0;
  text-align: center;
  height: auto;
}
.skincare-followup .selected-option {
  background-color: black;
  color: #ffd700;
  border: 1px solid black;
}

.nutripro .checkmark {
  position: absolute;
  right: 29px;
  bottom: 18px;
}

.skincare .checkmark {
  display: none;
}

.skincare-followup .checkmark {
  display: none;
}

@media all and (max-width: 768px) {
  .skincare .question-text {
    text-align: center;
  }

  .skincare-followup .question-text {
    text-align: center;
  }
}
</style>
