<template> 
  <div class="question-text mt-2 mb-7">{{ question.question_text }}</div>
  <div class="w-full">
    <textarea
     :id="question.name"
      rows="4"
      v-model.lazy="longanswer"
      :placeholder="question.placeholder"
      :readonly="!store.ifEditable()"
      type="text"
      class="h-[90px]"
    ></textarea>
  
  </div>
</template>

<script setup>
import { store } from "../../service/storeV2.js";
import {ref,watch} from "vue"

const props = defineProps({
  question:Object
})

const longanswer = ref(store.answers[props.question.question_id].open_answer||"");

 watch(longanswer,(newValue)=>{
 longanswer.value = newValue.replace(/[^\x00-\x7F]+/g, "");
 store.answers[props.question.question_id].open_answer=newValue;
 })

 const emits =defineEmits(["updateWarning","terminateSurvey"])
</script>

<style>
textarea{
    width:100%;
    height:280px;
    font-size:16px!important;
    border:1px solid #BCCCDC;
    border-radius:5px;
    padding: 10px!important;
}

.skincare textarea:focus{
  outline: none !important;
  border:1px solid #FFD700;
}


</style>
