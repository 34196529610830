<template>
  <div class="mt-[100px] mx-auto  max-w-[472px] flex flex-col items-center">
    <div class="login-title">Type in your details</div>
    <div class="login-please-type">We care about your privacy, please input the below information to securely access the questionnaire</div>
    <div class="w-full mx-auto max-w-[434px]">
      <div class="regular-label mb-1 text-base">First name</div>
      <input class="inputbox-layout grey-inputbox" v-model.trim="fname" placeholder="Jones" data-cy="fname"/>
    </div>
    <div class="w-full mx-auto max-w-[434px]">
      <div class="regular-label mb-1 text-base">Last name</div>
      <input class="inputbox-layout grey-inputbox" v-model.trim="lname" placeholder="Jones" data-cy="lname" />
    </div>
    <div class="w-full mx-auto max-w-[434px]">
      <div class="mb-1 text-base" :class="[showDateError?'red-label':'regular-label']">Date of Birth</div>
       <input class="inputbox-layout" :class="[showDateError?'red-inputbox':'grey-inputbox']" type="text" v-model="date" placeholder="MM/DD/YYYY" @input="formattedDate" data-cy="dob">
      <div v-if="showDateError" class="visiable-dob-reminder">Please enter the correct MM/DD/YYY format.</div>
    </div>
   <div>
   
  </div>
    <el-button class="loading-button mt-12" @click="submitForm" :disabled="showDateError || !completeForm" :loading="loadingReport" tabindex="4" data-cy="submit">
      Submit
    </el-button>
  </div>
</template>

<script>
import { useWindowSize } from "@vueuse/core";
import { ElNotification } from "element-plus";
import { store } from "../service/storeV2";
export default {
  emits: ["response"],
  setup() {
    const { width } = useWindowSize();
    return {
      width
    };
  },
  emits: ["moveToQuestions"],
  data() {
    return {
      loadingReport: false,
      fname: "",
      lname: "",
      store,
      date:""
    };
  },
  methods: {
      formattedDate() {
      // Remove all non-numeric characters from the input
      let cleaned = this.date.replace(/[^0-9]/g, "");
      // Add slashes between the numbers
      if (cleaned.length > 2) {
        cleaned = cleaned.slice(0, 2) + "/" + cleaned.slice(2);
      }
      if (cleaned.length > 5) {
        cleaned = cleaned.slice(0, 5) + "/" + cleaned.slice(5);
      }
      // Return the formatted date
      this.date = cleaned;
    },
    showFailNotification() {
      let position = this.width < 480 ? "bottom-left" : "top-right";
      ElNotification({
        title: "Verify Failed",
        message: "There are some errors during the verification process. Please try to verify again.",
        type: "error",
        position: position,
      });
    },
    async submitForm() {
      let dobs = this.date.split("/");
      let dob=[dobs[2],dobs[0],dobs[1]].join("-")
      this.loadingReport = true;
      try {
        let lastQ=await this.store.getToken(this.fname, this.lname, dob);
        this.$emit("moveToQuestions",lastQ);
      } catch (err) {
        console.log(err);
        this.loadingReport = false;
        this.showFailNotification();
      }
    },
  },
  computed: {
    showDateError() {
      if (this.date.length>=10){
        const regexp = /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/]\d{4}$/;
        return !regexp.test(this.date);
      }
      return false;
    },
     completeForm() {
      return this.fname && this.lname && this.date.length===10;
    },
  },
};
</script>
<style>
.Vibrant-America-Logo {
  padding: 23px 38px 23px 38px;
  margin-bottom: 105px;
  box-shadow: 0px 2px 4px rgba(120, 137, 149, 0.254784);
}
.login-title {
  font-weight: 700;
  font-size: 24px;
  color: #4a4a4a;
  margin-bottom: 19px;
}
.login-please-type {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 36px;
}
.regular-label {
  color: rgba(0, 0, 0, 0.87);
}
.red-label {
  color: #e14942;
}
.inputbox-layout {
  width:100%;
  height: 48px;
  border-radius: 5px;
  margin-bottom: 26px;
  padding-left: 27px;
}

.grey-inputbox {
  border: 1px solid #979797;
}

.date-input:focus,
.grey-inputbox:focus {
  outline: none !important;
}

.day-input,
.month-input {
  width: 28px;
  text-align: center;
}

.divider_placeholder {
  color: rgba(131, 131, 131, 0.655);
}

.divider_black {
  color: black;
}
.red-inputbox {
  border: 1px solid #e14942;
}
.red-inputbox:focus {
  border: 1px solid #e14942;
  outline: none !important;
}

.el-button.loading-button {
  width: 163px;
  height: 42px;
  border-radius: 6px;
  font-size: 16px;
  color: #ffffff;
  background: #3498db;
}

.el-button.loading-button:disabled {
  background: #d8d8d8;
}

.el-button.loading-button.is-loading {
  background: #3498db;
}

.visiable-dob-reminder {
  margin-top: -18px;
  margin-bottom: 41px;
  color: #e14942;
  visibility: visible;
  font-size: 12px;
  font-weight: 400;
}
.el-notification.right {
  height: 149px;
  width: 519px;
  border-radius: 8px;
  padding: 36px 40px 36px 40px;
}
.el-notification__title {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 8px;
}
.el-notification__content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #979797;
}
.el-notification__group {
  margin-left: 26.5px;
}

@media all and (max-width: 480px) {
  .Vibrant-America-Logo {
    padding: 12px 0px 12px 18px;
    margin-bottom: 40px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  }
  .login-title {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .login-please-type {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 30px;
  }
  .regular-label {
    font-size: 12px;
    line-height:12px;
    margin-bottom: 6px;
  }

  .red-label {
   font-size: 12px;
    line-height:12px;
    margin-bottom: 6px;
}
  .inputbox-layout {
    height: 36px;
    margin-bottom: 12px;
    padding: 11px 0px 11px 18px;
  }
  .el-button.loading-button {
    width: 140px;
    height: 36px;
    margin-top:40px;
  }
  .visiable-dob-reminder {
    margin-top: -6px;
    font-size: 10px;
  }
  .el-notification.left {
    width: 300px;
    height: 87px;
    padding: 21px, 23px, 21px, 23px;
    position: absolute;
    bottom: 0px;
    margin-right: auto;
    margin-left: auto;
    left: 0px;
    right: 0px;
  }
  .el-notification__title {
    font-size: 12px;
    margin-bottom: 4.6px;
  }
  .el-notification__content p {
    font-size: 10px;
    line-height: 13px;
  }
  .el-notification__group {
    margin-left: 15px;
  }
}
</style>
