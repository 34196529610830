<template>
  <div class="flex flex-col gap-y-16">
    <div v-for="(question, i) in question.questions" :key="i" >
      <div v-if="!question.dependent ||store.answers[question.dependent].option_id===question.condition">
        <component :is="question.type" :question="question"></component>
      </div>
    </div>
  </div>
</template>

<script>
import RadioQuestion from "./RadioQuestion.vue";
import SingleChoice from "./SingleChoice.vue"
import LongInput from "./LongInput.vue";
import { store } from "../service/store.js";

export default {
  name: "Followup Question",
  components: {
    RadioQuestion,
    SingleChoice,
    LongInput
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { store };
  },
  computed: {},

  methods: {},
};
</script>

<style></style>
