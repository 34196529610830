<template>
  <div
    class="flex flex-col justify-center header h-[44px] sm:h-[76px]"
    :class="surveyType"
  >
    <a
      v-if="url"
      :href="url"
      ><img
        class="ml-[10%] h-[40px] sm:h-[50px]"
        :src="logo"
    /></a>
    <div v-else>
      <img
        class="ml-[10%] h-[40px] sm:h-[50px]"
        :src="logo"
      />
    </div>
  </div>
</template>

<script setup>
import VWBrand from "../assets/VWBrand.svg";
import RegenereLogo from "../assets/skincareLogo.png";
import { computed, onMounted, ref, watch } from "vue";

const props = defineProps(["surveyType"]);
const logo = ref("");
const url = ref("");
const getLogo = (type) => {
  switch (type) {
    case "skincare":
    case "skincare-followup":
      logo.value = RegenereLogo;
      url.value = "https://www.regenere.com/";
      break;
    default:
      logo.value = VWBrand;
      url.value = "";
      break;
  }
};
onMounted(() => {
  getLogo(props.surveyType);
});

watch(
  () => props.surveyType,
  (val) => {
    getLogo(val);
  }
);
</script>

<style>
/* .header {
  background-repeat: no-repeat;
  background-position: left 10% center;
} */

.header.nutripro {
  background-color: white;
  border-bottom: solid 1px #dcdfe6;
  /* background-image: url("../assets/VWBrand.svg"); */
  background-size: auto 50%;
}
.header.skincare {
  background-color: black;
  /* background-image: url("../assets/skincareLogo.png"); */
  background-size: auto 60%;
}

.header.skincare-followup {
  background-color: black;
  /* background-image: url("../assets/skincareLogo.png"); */
  background-size: auto 60%;
}
</style>
