<template>
  <div>
    <div v-if="width > 480" class="flex justify-center fix-center" style="width: 1440px">
      <div style="width: 1440px; height: 1024px" class="flex items-center">
        <div style="padding-left: 120px">
          <div class="flex err-title">
            <div style="color: #36abbf">{{ errorCode }} - &nbsp;</div>
            {{ errorMsg }}
          </div>
          <div class="err-subtitle">{{ errorDescription }}</div>
          <div class="err-button flex items-center justify-center rounded font-medium cursor-pointer select-none hidden" @click="returnToHomePage()">
            Return to Homepage
          </div>
        </div>
        <img v-if="chooseImage" class="err-img" style="margin-bottom: -110px" :src="img" />
        <img v-if="!chooseImage" class="err-img" style="margin-bottom: 210px" :src="img" />
      </div>
    </div>
    <div v-if="width <= 480" class="flex flex-col items-center">
      <img :src="img" />
      <div class="font-medium mt-3 mb-1" style="font-size: 72px; color: #36abbf">{{ errorCode }}</div>
      <div class="font-medium mb-2" style="font-size: 20px">Oops!</div>
      <div class="" style="font-size: 20px"> {{ errorMsg }}</div>
    </div>
  </div>
</template>

<script>
import { useWindowSize } from "@vueuse/core";
import img from "../assets/404img.svg"

export default {
  setup() {
    const { width, height } = useWindowSize();
    return { width, height, img};
  },
  data() {
    return {
      chooseImage: Boolean,
      errorMsg: "",
      errorDescription: "",
      img
    };
  },
  props: {
    errorCode: {
      type: Number,
      default: 404,
    },
  },
  mounted() {

      this.errorMsg = "Page Not Found";
      this.errorDescription="Oops! We can't seem to find the page you're looking for"

  },
};
</script>

<style>
.fix-center {
  position: fixed;
  /* center the element */
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
}

.err-title {
  font-size: 40px;
  line-height: 47px;
}

.err-subtitle {
  margin-top: 38px;
  font-size: 21px;
  line-height: 25px;
}

.err-button {
  margin-top: 56px;
  margin-bottom: 132px;
  font-size: 15px;
  color: white;

  height: 42px;
  width: 177px;
  background: #6ec6d1;
}

.err-img {
  margin-left: 84px;
}

.rotated-90 {
  transform: rotate(90deg);
}
</style>