<template>
  <div class="flex justify-center items-center">
    <div v-if="type === 'nutripro'|| type==='nutripro-followup'" style="max-width: 472px">
      <div><img :src="SmileFace" style="width: 60px" /></div>
      <p class="welcome-title mt-6">You‘re all set!</p>
      <ul class="welcome-description mt-8">
        <li>Next up, WellProz will personalize your plan based on the information you just gave us!</li>
        <li>You can click review button to go over your questionnaire.</li>
      </ul>
      <div class="flex flex-wrap justify-between mt-40">
        <button
          class="button-style mx-auto"
          style="background-color: #fff; color: rgba(52, 152, 219, 1); border: 1px solid rgba(52, 152, 219, 1)"
          @click="$emit('review')"
        >
          Review
        </button>
        <button class="button-style mx-auto hidden" @click="done">Done</button>
      </div>
    </div>
    <div v-if="type === 'skincare' || type === 'skincare-followup'" class="flex flex-col items-center mt-20" style="max-width: 720px">
        <svg xmlns="http://www.w3.org/2000/svg" width="205" height="205" viewBox="0 0 205 205" fill="none">
          <path
            d="M102.305 204.61C158.807 204.61 204.61 158.807 204.61 102.305C204.61 45.8035 158.807 0 102.305 0C45.8035 0 0 45.8035 0 102.305C0 158.807 45.8035 204.61 102.305 204.61Z"
            fill="#B0D3DA"
          />
          <path
            d="M93.3161 170.609L92.502 169.217C80.0667 147.954 47.0214 102.83 46.6876 102.377L46.2109 101.727L57.4693 90.5609L93.107 115.535C115.546 86.3134 136.479 66.2427 150.134 54.5222C165.071 41.7012 174.794 35.7988 174.893 35.7422L175.114 35.6094H194.211L192.387 37.2398C145.471 79.1772 94.6193 168.311 94.1128 169.207L93.3161 170.609Z"
            fill="#FFD500"
          />
          <path
            d="M102.305 204.61C158.807 204.61 204.61 158.807 204.61 102.305C204.61 45.8035 158.807 0 102.305 0C45.8035 0 0 45.8035 0 102.305C0 158.807 45.8035 204.61 102.305 204.61Z"
            fill="#C4E1DC"
          />
          <path
            d="M93.3161 170.609L92.502 169.217C80.0667 147.954 47.0214 102.83 46.6876 102.377L46.2109 101.727L57.4693 90.5609L93.107 115.535C115.546 86.3134 136.479 66.2427 150.134 54.5222C165.071 41.7012 174.794 35.7988 174.893 35.7422L175.114 35.6094H194.211L192.387 37.2398C145.471 79.1772 94.6193 168.311 94.1128 169.207L93.3161 170.609Z"
            fill="#1DA087"
          />
        </svg>
      <div class="mt-10 font-medium text-[32px] text-center">Submission Successful !</div>
      <div class="mt-9 text-[16px] text-center">We will begin customizing your skincare solution and will let you know once it is ready to ship.</div>
    </div>
  </div>
</template>

<script>
import SmileFace from "../assets/smileFace.svg";
export default {
  name: "Success Page",
  setup() {
    return { SmileFace };
  },
  emits: ["review"],
  methods: {
    done() {
      //to be done
    },
  },
  props: {
    type: String,
  },
};
</script>

<style></style>
