<template>
  <div class="flex flex-col">
    <div class="text-center question-number">Question {{ no }}/{{ questionLength }}</div>
    <div class="flex flex-col gap-y-16">
      <div v-for="q in questions" :key="q">
        <template v-if="!q.dependent || store.answers[q.dependent].option_id === q.condition">
          <component :is="q.question_type" :question="q" @updateWarning="updateWarning" @terminateSurvey="terminateSurvey"></component>
        </template>
      </div>
    </div>

    <div class="mt-28 mb-6">
      <div v-if="warningMsg" class="text-red-500 text-center mb-2">{{ warningMsg }}</div>
      <div class="flex flex-wrap justify-center gap-x-12 gap-y-5">
        <button
          v-if="no < questionLength"
          class="button-style flex items-center justify-center gap-2"
          @click="next"
          :disabled="nextDisabled"
          data-cy="next"
        >
          Next Question <img :src="NextArrow" />
        </button>
        <el-button
          v-loading="loadingsave"
          v-if="store.ifEditable() && no > 1 && store.surveyType !== 'skincare-followup'"
          class="button-style"
          @click="save"
          :disabled="no <= 1"
        >
          Save
        </el-button>
        <button
          v-if="store.ifEditable() && no === questionLength"
          class="button-style"
          :disabled="nextDisabled"
          @click="submit"
          data-cy="submitQ"
        >
          Submit
        </button>
      </div>
      <div
        v-if="questions[0].none_id"
        class="flex justify-center mt-6"
        :class="{ 'cursor-pointer': store.ifEditable() }"
      >
        <div
          @click="selectAllNone"
          class="text-center italic font-medium leading-normal text-[14px] md:text-[16px] underline"
        >
          None of the above
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessageBox } from "element-plus";
import SingleChoice from "./SingleChoice.vue";
import MultipleChoice from "./MultipleChoice.vue";
import NextArrow from "@/assets/nextArrow.svg";
import HeightWeightInput from "./HeightWeightInput.vue";
import LongInput from "./LongInput.vue";
import RadioQuestion from "./RadioQuestion.vue";
import UploadImage from "./UploadImage.vue";
import SingleSeverityChoice from "../SingleSeverityChoice.vue";
import { store } from "../../service/storeV2.js";

export default {
  name: "Question Item",
  components: {
    SingleChoice,
    MultipleChoice,
    HeightWeightInput,
    LongInput,
    UploadImage,
    RadioQuestion,
    SingleSeverityChoice,
  },
  props: {
    no: {
      type: Number,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
    questionLength: {
      type: Number,
      required: true,
    },
  },
  data() {
    return { store, loadingsave: false, warningMsg: "" };
  },
  computed: {
    questions() {
      if (this.store.questionMap[this.index][0].question_type === "HeightWeightInput") {
        return [this.store.questionMap[this.index][0]];
      } else if (this.store.questionMap[this.index][0].question_type === "UploadImage") {
        return [
          {
            question_type: "UploadImage",
            question:this.store.questionMap[this.index][0].question_text,
            uploads: [
              { label: "Upload(left)", value: "0", sample: "leftSample.png" },
              { label: "Upload(front)", value: "1", sample: "frontSample.png" },
              { label: "Upload(right)", value: "2", sample: "rightSample.png" },
            ],
            question_id: [21, 22, 23],
          },
        ];
      }
      return this.store.questionMap[this.index];
    },
    nextDisabled() {
      return this.store.questionMap[this.index]?.some(
        (q) => !this.store.validateAnswers(q, this.store.answers[q.question_id])
      );
    },
  },
  setup() {
    return { NextArrow };
  },
  methods: {
    next() {
      this.$emit("nextQuestion");
    },
    updateWarning(msg) {
      this.warningMsg = msg;
    },
   terminateSurvey(question_id,option){

      ElMessageBox.confirm(
        option.warningSubtitle,
        option.warningTitle,
        {
          customClass: `${this.store.surveyType} confirm-submit-box`,
          confirmButtonText: "I Understand",
          cancelButtonText: "Back to Edit",
        }
      )
        .then(() => {
          return this.store.uploadData(true);
        })
        .then((res) => {
          if (res === "submitted") {
            this.$emit("terminateSurvey");
          }
        }).catch(()=>{
          this.store.answers[question_id].option_id=null;
        });
    },
   
    submit() {
      ElMessageBox.confirm(
        "You can't make changes once this is submitted, Please verify all of your answers are correct.",
        "Reminder",
        {
          customClass: `${this.store.surveyType} confirm-submit-box`,
          confirmButtonText: "Complete Submission",
          cancelButtonText: "Back to Edit",
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          return this.store.uploadData(true);
        })
        .then((res) => {
          if (res === "submitted") {
            this.$emit("successSumbit");
          }
        }).catch(()=>{});
    },
    async save() {
      this.loadingsave = true;
      await this.store.uploadData(false);
      this.loadingsave = false;
    },
    selectAllNone() {
      // only work for first question of the page
      const question_type = this.questions[0].question_type;
      const none_id = this.questions[0].none_id;
      const question_id = this.questions[0].question_id;
      if (this.store.ifEditable()) {
        if (question_type === "MultipleChoice") {
          this.store.answers[question_id].option_id = [none_id];
        } else if (question_type === "SingleChoice" || question_type === "RadioQuestion") {
          this.store.answers[question_id].option_id = none_id;
        } else if (question_type === "SingleSeverityChoice") {
          this.question.questions.forEach(
            (q) =>
              (this.store.answers[q.question_id] = {
                question_id: q.question_id,
                option_id: q.option_id[0],
              })
          );
        }
        if (this.no < this.questionLength) this.next();
      }
    },
  },
  emits: ["nextQuestion", "successSumbit",'terminateSurvey'],
};
</script>

<style>
.question-number {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(151, 151, 151, 1);
}

.skincare .question-number {
  display: none;
}

.skincare .question-number {
  display: none;
}

.skincare-followup .question-number {
  display: none;
}

.skincare-followup .question-number {
  display: none;
}

.el-button.button-style,
.button-style {
  width: 186px;
  height: 42px;
  color: white;
  font-size: 16px;
  line-height: 42px;
  border-radius: 6px;
}

.confirm-submit-box {
  width: 80%;
  max-width: 585px;
}

.confirm-submit-box .el-message-box__title {
  padding: 68px 0 70px 0;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.confirm-submit-box .el-message-box__message {
  padding: 0 32px 0 32px;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
}

.confirm-submit-box .el-message-box__btns {
  padding: 60px 50px 80px 50px;
  justify-content: space-between;
  gap: 20px;
}

.nutripro.confirm-submit-box .el-button.el-button--primary,
.skincare.confirm-submit-box .el-button.el-button--primary {
  color: white;
}
.skincare-followup.confirm-submit-box .el-button.el-button--primary {
  color: white;
}

.nutripro.confirm-submit-box .el-button {
  color: rgba(52, 152, 219, 1);
  border-color: rgba(52, 152, 219, 1);
}

.skincare.confirm-submit-box .el-button {
  color: black;
  border-color: black;
}
.skincare-followup.confirm-submit-box .el-button {
  color: black;
  border-color: black;
}

.nutripro .el-button.button-style,
.nutripro .button-style,
.nutripro.confirm-submit-box .el-button--primary {
  background-color: rgba(52, 152, 219, 1);
}
.nutripro .button-style:disabled {
  background: rgba(52, 152, 219, 0.5);
}

.skincare .button-style,
.skincare .el-button.button-style,
.skincare.confirm-submit-box .el-button--primary {
  background-color: #ffd700;
  border-color: #ffd700;
}

.skincare-followup .button-style,
.skincare-followup .el-button.button-style,
.skincare-followup.confirm-submit-box .el-button--primary {
  background-color: #ffd700;
  border-color: #ffd700;
}

.skincare .button-style:disabled {
  background-color: rgba(255, 215, 0, 0.5);
}
.skincare-followup .button-style:disabled {
  background-color: rgba(255, 215, 0, 0.5);
}

.skincare .confirm-submit-box .el-button {
  color: #ffd700;
  border-color: #ffd700;
}
.skincare-followup .confirm-submit-box .el-button {
  color: #ffd700;
  border-color: #ffd700;
}

.skincare.confirm-submit-box .el-message-box__headerbtn .el-message-box__close:hover {
  color: #ffd700 !important;
}
.skincar-followup.confirm-submit-box .el-message-box__headerbtn .el-message-box__close:hover {
  color: #ffd700 !important;
}
.confirm-submit-box .el-button--primary {
  background-color: rgba(52, 152, 219, 1);
  color: white;
}

.confirm-submit-box .el-message-box__btns button:nth-child(2) {
  margin-left: auto;
}

@media only screen and (max-width: 436px) {
  .el-button,
  .el-button--primary {
    margin: auto;
  }
}
</style>
