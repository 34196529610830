<template>
<!-- for nutripro only -->
  <div class="flex flex-col items-center max-w-[500px]">
    <div class="question-text text-center mt-2 mb-7">What is your height, weight and gender?</div>
    <div class="flex flex-wrap gap-y-4 justify-between">
      <div v-for="(question, i) in open_question" :key="i" class="flex basis-[46%] min-w-min last:basis-full">
        <input
          type="number"
          min="0"
          :id="i"
          :name="question.question_id"
          class="box-border text-center grow w-full"
          v-model="store.answers[question.question_id].open_answer"
          :readonly="!store.ifEditable()"
        />
        <div class="text-label pl-2 w-[38px]">{{ question.label }}</div>
      </div>
    </div>
    <div class="flex w-full justify-between h-[48px] mt-8">
      <div
        v-for="option in gender_question.options"
        :key="option.option_id"
        class="box-border text-center text-[16px] leading-[20px] py-[14px] basis-[46%] flex justify-between pl-4 pr-2 sm:pl-8 sm:pr-4"
        :class="{ 'selected-option': isSelected(option.option_id),'cursor-pointer':store.ifEditable() }"
        @click="select(option.option_id)"
      >
        {{ option.option_content }}
        <img v-if="isSelected(option.option_id)" :src="checkmark"  />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../../service/storeV2.js";
import checkmark from "@/assets/checkmark.svg";
export default {
  name: "Mulitple Input",
  data() {
    const open_question =[{question_id:'3',label:"ft"},{question_id:'20',label:"in"},{question_id:'4',label:"lbs"}]
    const gender_question={question_id:'5',options:[{ "option_content": "Female", "option_id": 11 }, { "option_content": "Male", "option_id": 12 }]}
    return {
      store,checkmark,open_question,gender_question
    };
  },
  methods: {
   select(option_id) {
      if( this.store.ifEditable())
      this.store.answers[this.gender_question.question_id].option_id=option_id;//hard code for gender
    },
    isSelected(option_id){
      return option_id===this.store.answers[this.gender_question.question_id]?.option_id;//hard code for gender
    }
  },

};
</script>

<style>
.box-border {
  border: 1px solid #bcccdc;
  border-radius: 5px;
  height: 48px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.text-label {
  line-height: 48px;
  font-size: 16px;
}
</style>
