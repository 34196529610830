<template>
  <div class="question-text mt-2 mb-2">{{ question.question }}</div>
  <div class="text-[14px] text-[#939393] mb-10 subtitle">
    <div v-if="question.max_selections">Select up to {{ question.max_selections }} that apply</div>
    <div v-else>Choose all that apply</div>
  </div>
  <div class="grid options-layout items-center w-full">
    <div
      v-for="(choice, i) in question.choices"
      :key="i"
      class="question-choice"
      :class="{ 'selected-option': isSelected(i), 'cursor-pointer': store.ifEditable() }"
      @click="select(i)"
      :data-cy="question.option_id[i]"
    >
      {{ choice }}
      <img v-if="isSelected(i)" :src="checkmark" class="checkmark" />
    </div>
  </div>
</template>

<script>
import checkmark from "../assets/checkmark.svg";
import { store } from "../service/store.js";
export default {
  name: "Single Choice",
  props: {
    question: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      store,
      checkmark: checkmark,
    };
  },
  methods: {
    select(i) {
      if (this.store.ifEditable()) {
        const { question_id, option_id } = this.question;
        let oldAnswers = this.store.answers[question_id];
        if (oldAnswers.includes(option_id[i])) {
          //deselect
          oldAnswers = oldAnswers.filter((id) => id !== option_id[i]);
        } else {
          //select
          if (this.question.max_selections && oldAnswers.length >= this.question.max_selections) {
            //do nothing
          } else {
            //   const noneOption = this.question.choices.findIndex(c =>c==="None")
            //  oldAnswers = oldAnswers.filter((id) => Number(id)!== this.question.option_id[noneOption]);
            if (this.question.none_id) oldAnswers = oldAnswers.filter((id) => Number(id) !== this.question.none_id);
            oldAnswers = [...oldAnswers, option_id[i]];
          }
        }
        this.store.answers[question_id] = [...oldAnswers];
      }
    },
    isSelected(i) {
      return this.store.answers[this.question.question_id]?.includes(this.question.option_id[i]);
    },
  },
};
</script>

<style>
.selected-option {
  background: #f8fafb;
  border: 1px solid #3498db;
}

.checkmark {
  position: absolute;
  right: 29px;
  bottom: 18px;
}

.nutripro .subtitle {
  text-align: center;
}

@media all and (max-width: 768px) {
  .skincare .subtitle {
    text-align: center;
  }
  .skincare-followup .subtitle {
    text-align: center;
  }
}
</style>
