import { reactive } from 'vue'
import axios from 'axios';
import { ElMessage, ElNotification } from 'element-plus'
import nutriprozQuestions from "./questions.json";
import nutriprozFollowupQuestions from "./nutriproFollowupQuestion.json"
import skincareQuestions from "./skincareQuestions.json";
import skincareFollowupQuestions from "./skincareFollowupQuestion.json";

export const store = reactive({
  surveyType: "",
  questionTemplateId: 1,
  barcode: "",
  dataLoaded: false,
  if_submitted: "",
  answers: {},
  multpleQuestions: [],
  questions: [],
  baseUrl: "https://www.vibrant-america.com/lisapi/v1/lis/interactive-report-service",
  user: {},

  setBarcode(barcode) {
    this.dataLoaded = false;
    this.if_submitted = ""
    this.answers = {};
    this.barcode = barcode;
  },

  async getToken(fname, lname, dob) {
    const url = "https://www.vibrant-america.com/lisapi/v1/lis/interactive-report-service/auth/security/"
    const config = {
      params: { 'barcode': this.barcode, 'firstname': fname, 'lastname': lname, 'dob': dob },
    }
    return axios.get(url, config).then(response => {
      let token = response.data.token
      localStorage.setItem("jwtToken", token)
      return this.getData(token)
    }).catch(err => {
      console.log(err)
      throw (err)
    })
  },

  async getData(token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    const url = this.baseUrl + `/questions-data/getAnswer?barcodeId=${this.barcode}&templateId=${this.questionTemplateId}`
    return axios.get(url, config)
      .then(response => {
        this.dataLoaded = true;
        this.if_submitted = response.data.if_submitted === true ? true : false;
        this.user = response.data.user_info
        this.formatAnswers(response.data.answers)
        this.removeNewQuestion()
        let lastQ = this.if_submitted ? 1 : this.getLastQuestion();
        return lastQ;
      }).catch(error => {
        ElNotification({
          title: "Verify Failed",
          message: "There are some errors during the verification process. Please try to verify again.",
          type: "error",
          position: "top-right",
        });
        throw (error)
      })
  },

  initAnswers(questions) {
     questions?.forEach(q => {
      if (q.type === "MultipleChoice") {
        this.initMultipleQuestion(q.question_id)
      } else if (q.type === 'FollowupQuestion'||q.type==='SingleSeverityChoice') {
        this.initAnswers(q.questions)
      } else if( q.type==='HeightWeightInput'||q.type==='UploadImage'){
         q.question_id.forEach((subq)=> this.initSimpleQuestion(subq))
      }else {
        this.initSimpleQuestion(q.question_id)
      }
    })
  },

  initMultipleQuestion(i) {
    this.multpleQuestions.push(i);
    this.answers[i] = []
  },

  initSimpleQuestion(i) {
    this.answers[i] = { question_id:i,option_id: null, open_answer: null }
  },

  setType(type) {
    this.surveyType = type;
    if (this.surveyType === 'nutripro') {
      this.questionTemplateId = 1
      this.questions = nutriprozQuestions
    }
    if (this.surveyType === 'nutripro-followup') {
      this.questionTemplateId = 4
      this.questions = nutriprozFollowupQuestions
    }
    if (this.surveyType === 'skincare') {
      this.questionTemplateId = 2
      this.questions = skincareQuestions
    }
    if (this.surveyType === 'skincare-followup') {
      this.questionTemplateId = 3
      this.questions = skincareFollowupQuestions
    }
  },

  removeNewQuestion() {
    if (this.if_submitted) {
      this.questions = this.questions.filter((q) => !this.validateAnswers(q, this.answers))
    }
  },

  formatAnswers(res) {
    this.answers = {};
    this.initAnswers(this.questions);
    //this.multpleQuestions.forEach(i => { this.answers[i] = [] })
    let lastQ = 1;
    if (res?.length) {
      res.forEach(answer => {
        const { question_id, option_id, open_answer } = answer
        //render answer
        if (this.multpleQuestions.includes(question_id)) {
          this.answers[question_id].push(option_id)
        } else {
          this.answers[question_id] = answer
        }
      })
    }
  },

  getLastQuestion() {
    let lastQ = 0;
    this.questions.some((q, i) => {
      if (this.validateAnswers(q, this.answers))
        return lastQ = i + 1
    })
    // lastQ=0: all questions have valid answer
    return lastQ === 0 ? this.questions.length : lastQ;
  },

  validateAnswers(question, answers) {//return true when there is error/empty
    if (question.type === "SingleChoice" || question.type === "RadioQuestion") return this.validateInput(answers[question.question_id]);
    else if (question.type === "MultipleChoice") return this.validateMulitiple(answers[question.question_id]);
    else if (question.type === "HeightWeightInput") return this.validateMultiInput(question.question_id, answers);
    else if (question.type === "UploadImage") return this.validateMultiInput(question.question_id, answers);
    else if (question.type === "FollowupQuestion") return this.validateFollowup(question.questions, answers);
    else if (question.type === "LongInput") return question.required && this.validateInput(answers[question.question_id]);
    else if (question.type === "SingleSeverityChoice") return this.validateSingleSeverity()
    return false;
  },

  validateInput(answer) {
    if (!answer) return true;
    return answer.option_id === null && (answer.open_answer === undefined || answer.open_answer === null || answer.open_answer.toString().trim() === "")
  },
  validateMulitiple(answer) {
    return answer.length === 0;
  },
  validateMultiInput(question_ids, answers) {
    return question_ids.some((id) => this.validateInput(answers[id]));
  },
  validateFollowup(questions, answers) {
    for (let question of questions) {
      if (!question.dependent) {
        if (this.validateAnswers(question, answers)) return true;
      } else {
        if (answers[question.dependent].option_id === question.condition) {
          if (this.validateAnswers(question, answers)) return true;
        }
      }
    }
    return false;
  },
  validateSingleSeverity(questions, answers) {
    // if(!this.answers[41] && !this.answers[42] && !this.answers[43] && !this.answers[44] && !this.answers[45]){
    //   return true
    // }
    // return false
  },

  prepareUpload() {
    let payload = [];
    Object.keys(this.answers).forEach(question_id => {
      question_id = Number(question_id)
      if (question_id) {
        if (this.multpleQuestions.includes(question_id)) {
          this.answers[question_id].forEach(option_id => {
            payload.push({ question_id: question_id, option_id: option_id })
          })
        } else if (this.answers[question_id].open_answer) {
          payload.push({ question_id: question_id, open_answer: this.answers[question_id].open_answer.toString() })
        } else if (this.answers[question_id].option_id){
          payload.push({ question_id: question_id, option_id: this.answers[question_id].option_id })
        }
      }
    })
    return payload;
  },

  async uploadData(if_submitted) {
    let payload = {
      "if_submitted": if_submitted,
      "julien_barcode": this.barcode,
      "template_id": this.questionTemplateId,
    };
    payload['answers'] = this.prepareUpload();
    const token = localStorage.getItem("jwtToken")
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.post(this.baseUrl + "/questions-data/postAnswer", payload, config).then(res => {
      this.if_submitted = if_submitted;
      if (!this.if_submitted) {
        ElMessage({
          message: 'Saved successfully.',
          type: 'success',
        })
        return Promise.resolve("uploaded");
      } else {
        return Promise.resolve("submitted");
      }
    }).catch(err => {
      ElMessage.error('Sorry. Something went wrong.Please try again.')
      console.log(err);
    })
  },

  ifEditable() {
    return !this.if_submitted;
  },

  async uploadFile(file) {
    const token = localStorage.getItem("jwtToken")
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data;'
      }
    }
    return axios.post(this.baseUrl + "/questions-data/uploadImage", file, config).then(res => {
      return res.data.imagePath
    }).catch(err => {
      ElMessage.error('Sorry. Something went wrong.Please try again.')
      console.log(err);
    })
  },


})
