<template>
    <div class="question-text md:text-start text-center md:mb-[72px] mb-6 ">{{ question.question }}</div>
  <div class="flex flex-col gap-y-8">
    <div class="" v-for="(singleQuestion, i) in question.questions" :key="i">
      <SeverityChoice :question="singleQuestion" />
    </div>
  </div>
</template>

<script>
import SeverityChoice from "./SeverityChoice.vue";
import { store } from "../service/store.js";
export default {
  components: {
    SeverityChoice,
  },
  props: {
    question: {
      type: Object,
      require: true,
    },
  },
  methods: {
  },
  data() {
    return {
      store,
    };
  },
};
</script>
