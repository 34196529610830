<template>
  <div class="question-text mt-2 ">{{ question.question_text }}</div>
  <div class="mt-2 text-[16px]">{{question.description}}</div>
  <div class="flex flex-wrap justify-center md:justify-start items-center w-full gap-x-20 gap-y-4 mt-8 md:mt-12">
    <div
      v-for="option in question.options"
      :key="option.option_id"
      class="flex items-center gap-x-3"
      :class="{ 'cursor-pointer': store.ifEditable() }"
      @click="select(option.option_id)"
    >
      <div v-if="isSelected(option.option_id)">
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.88477 0H16.8848C17.9848 0 18.8848 0.9 18.8848 2V16C18.8848 17.1 17.9848 18 16.8848 18H2.88477C1.78477 18 0.884766 17.1 0.884766 16V2C0.884766 0.9 1.78477 0 2.88477 0ZM14.4848 4.6L15.8848 6L7.88477 14L3.88477 10L5.28477 8.6L7.88477 11.2L14.4848 4.6Z"
            fill="#F3D332"
          />
        </svg>
      </div>
      <div v-else class="w-[19px] h-[18px] border-[2px] border-black rounded"></div>
      <div>{{ option.option_content }}</div>
    </div>
  </div>
</template>

<script>
import { store } from "../../service/storeV2.js";
export default {
  name: "Radio Question",
  props: {
    question: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      store,
    };
  },
  methods: {
    select(option_id) {
      if (this.store.ifEditable()) this.store.answers[this.question.question_id].option_id= option_id;
    },
    isSelected(option_id) {
      return option_id === this.store.answers[this.question.question_id].option_id;
    },
  },
  emits:["updateWarning","terminateSurvey"]
};
</script>

<style></style>
