<template>
  <start-page
    v-if="!startSurvey"
    :surveyType="surveyType"
    @goToSurvey="startSurvey = true"
  ></start-page>
  <div v-else>
    <survey-header
      :class="surveyType === 'nutripro-followup' ? 'nutripro' : surveyType"
      :surveyType="surveyType"
    />
    <el-container
      class="w-full"
      v-loading.fullscreen.lock="fullscreenLoading"
    >
      <div
        class="min-w-[220px] w-full survey-wrapper"
        :class="surveyType === 'nutripro-followup' ? 'nutripro' : surveyType"
      >
        <div v-if="cur === 0"><login-page @moveToQuestions="moveToQuestions" /></div>
        <div
          v-if="cur > 0"
          class="flex flex-col mt-6"
        >
          <div
            v-if="store.user?.dob && store.user.dob != '1000-01-01'"
            class="user-bg-grey flex justify-between px-2 sm:self-end sm:gap-x-8 sm:px-8 py-2"
          >
            <div>
              <div class="user-tag-text text-center">First Name</div>
              <div class="user-info-text text-center">{{ store.user?.first_name }}</div>
            </div>
            <div>
              <div class="user-tag-text text-center">Last Name</div>
              <div class="user-info-text text-center">{{ store.user?.last_name }}</div>
            </div>
            <div>
              <div class="user-tag-text text-center">DOB</div>
              <div class="user-info-text text-center">{{ store.user?.dob }}</div>
            </div>
            <div>
              <div class="user-tag-text text-center">Gender</div>
              <div class="user-info-text text-center">{{ store.user?.gender }}</div>
            </div>
          </div>
          <div
            v-if="cur != store.questions.length + 1"
            class="flex flex-wrap items-center mt-6 gap-y-2 gap-x-6"
          >
            <div>
              <div
                v-if="surveyType === 'skincare' || surveyType === 'skincare-followup'"
                class="questionnaire-title flex gap-x-2 items-center"
              >
                <img :src="skinQuizLogo" />SKIN QUIZ
              </div>
              <div
                v-if="surveyType === 'nutripro'"
                class="questionnaire-title"
              >
                NutriProZ Test Questionnaire
              </div>
              <div
                v-if="surveyType === 'nutripro-followup'"
                class="questionnaire-title"
              >
                NutriProZ Test Follow-Up Questionnaire
              </div>
            </div>
            <div
              v-if="!store.ifEditable()"
              class="view-only-tag flex gap-x-1.5 text-[#3498DB]"
            >
              <img :src="EyeIcon" />View Only
            </div>
          </div>
        </div>
        <progress-bar
          v-if="cur > 0 && cur <= Object.keys(store.questionMap).length"
          :cur="cur"
          :questionLength="Object.keys(store.questionMap).length"
          class="mt-7"
          :class="[cur === 1 ? 'mb-10' : 'mb-4']"
        />
        <div
          v-if="cur > 1 && cur <= store.questions.length"
          @click="cur--"
          class="previous-question flex gap-x-2 items-center mb-10"
        >
          <img :src="PreArrow" />Previous
        </div>
        <div
          v-for="i in Object.keys(store.questionMap).length"
          :key="i"
          class="single-question"
        >
          <div v-if="cur === i">
            <question-item
              :no="i"
              :index="Object.keys(store.questionMap)[i - 1]"
              :questionLength="Object.keys(store.questionMap).length"
              @next-question="cur++"
              @successSumbit="cur = store.questions.length + 1"
              @terminateSurvey="cur = store.questions.length + 2"
            />
          </div>
        </div>
        <div
          v-if="cur === store.questions.length + 1"
          class="mt-8"
        >
          <success-page
            :type="surveyType"
            @review="cur = 1"
          />
        </div>
        <div
          v-if="cur === store.questions.length + 2"
          class="mt-8"
        >
          <terminated-page />
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
import SurveyHeader from "../components/SurveyHeader.vue";
import { useRoute } from "vue-router";
import StartPage from "./startPage.vue";
import ProgressBar from "../components/ProgressBar.vue";
import QuestionItem from "../components/V2/QuestionItem.vue";
import LoginPage from "./LoginPageVersion2.vue";
import SuccessPage from "./SuccessPage.vue";
import TerminatedPage from "./TermiantedPage.vue";
import skinQuizLogo from "../assets/skinQuizLogo.png";
import PreArrow from "../assets/preArrow.svg";
import EyeIcon from "../assets/eye.svg";
import { store } from "../service/storeV2.js";

export default {
  name: "Question Panel",
  components: {
    ProgressBar,
    QuestionItem,
    SuccessPage,
    LoginPage,
    SurveyHeader,
    StartPage,
    TerminatedPage,
  },
  props: {
    surveyType: {
      type: String,
    },
  },
  data() {
    return {
      startSurvey: false,
      //cur=0: welcome page, cur=1-questions.length:question,cur=question.length+1:success page
      cur: store.dataLoaded ? 1 : 0,
      store,
      fullscreenLoading: false,
    };
  },
  methods: {
    moveToQuestions(lastQ) {
      this.cur = lastQ;
    },
    refreshData() {
      this.startSurvey = false;
      this.cur = 0;
      this.initQuestionnaire();
    },
    async initQuestionnaire() {
      this.store.setType(this.surveyType);
      this.store.setBarcode(this.route.params.barcode);
      const jwtToken = this.route.query.jwt_token;
      if (jwtToken) {
        localStorage.setItem("jwtToken", jwtToken);
      }
      if (localStorage.getItem("jwtToken") != null) {
        try {
          this.fullscreenLoading = true;
          this.cur = await this.store.getQuestions(localStorage.getItem("jwtToken"));
        } catch {
          localStorage.removeItem("jwtToken");
        } finally {
          this.fullscreenLoading = false;
        }
      }
    },
  },
  setup() {
    const route = useRoute();
    return {
      PreArrow,
      EyeIcon,
      route,
      skinQuizLogo,
    };
  },
  async mounted() {
    window.addEventListener("popstate", this.refreshData);
    this.initQuestionnaire();
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.refreshData);
  },
};
</script>

<style>
.survey-wrapper.nutripro {
  margin: 0 auto;
  padding: 0 10%;
}
.survey-wrapper.skincare {
  width: 100vw;
  padding: 0 10%;
  position: relative;
  height: 100%;
  min-height: calc(100vh - 44px - 37.3vw);
  padding-bottom: 168px;
}
.survey-wrapper.skincare-followup {
  width: 100vw;
  padding: 0 10%;
  position: relative;
  height: 100%;
  min-height: calc(100vh - 44px - 37.3vw);
  padding-bottom: 168px;
}

.survey-wrapper.skincare:after {
  content: "";
  width: 100%;
  height: 37.3vw;
  display: block;
  position: absolute;
  transform: translateY(100%) translateX(50%);
  bottom: 0;
  right: 50%;
  background-image: url("../assets/skincarebg5.png");
  background-repeat: no-repeat;
  background-size: 100vw auto;
}

.survey-wrapper.skincare-followup:after {
  content: "";
  width: 100%;
  height: 37.3vw;
  display: block;
  position: absolute;
  transform: translateY(100%) translateX(50%);
  bottom: 0;
  right: 50%;
  background-image: url("../assets/skincarefollowupbg3.png"),
    url("../assets/skincarefollowupbg4.svg");
  background-repeat: no-repeat;
  background-size: 100vw auto, 100vw auto;
}

.questionnaire-title {
  font-size: 16px;
  font-weight: 500;
}
.single-question {
  margin: 0 auto;
}

.nutripro .single-question {
  width: 90%;
  max-width: 556px;
}

.previous-question {
  cursor: pointer;
  top: 95px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: rgba(74, 74, 74, 1);
}

.view-only-tag {
  font-size: 12px;
  line-height: 24px;
  background-color: rgba(216, 216, 216, 1);
  padding: 0 10px;
  border-radius: 3px;
}

.user-bg-grey {
  background-color: #f8fafb;
}

.user-tag-text {
  color: #979797;
  font-size: 8px;
}

.user-info-text {
  font-size: 10px;
}

@media (min-width: 768px) {
  .user-tag-text {
    font-size: 14px;
  }

  .user-info-text {
    font-size: 16px;
  }

  .view-only-tag {
    font-size: 14px;
    line-height: 30px;
    padding: 0 18px;
    border-radius: 2px;
  }
  .survey-wrapper.skincare {
    width: 70vw;
    min-height: calc(100vh - 78px);
  }
  .survey-wrapper.skincare:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    height: 100%;
    width: 30vw;
    background-position: right 0 top 10%, left 20% top 50%, right 10% bottom 15%;
    background-image: url("../assets/skincarebg1.png"), url("../assets/skincarebg2.png"),
      url("../assets/skincarebg3.png");
    background-repeat: no-repeat;
    background-size: auto 30%, auto 30%, auto 15%;
    background-color: #f4eacd;
  }

  .survey-wrapper.skincare-followup {
    width: 70vw;
    min-height: calc(100vh - 78px);
  }
  .survey-wrapper.skincare-followup:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    height: 100%;
    width: 30vw;
    background-position: right 0 top 0;
    background-image: url("../assets/skincarefollowupbg2.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}

@media (min-width: 1280px) {
  .survey-wrapper.skincare {
    width: 75vw;
    background-image: url("../assets/skincarebg4.png");
    background-repeat: no-repeat;
    background-size: auto 30%;
    background-position: left 10% bottom 10%;
  }
  .survey-wrapper.skincare:after {
    background-position: right 0 top 10%, left 20% top 60%, right 10% bottom 10%;
    background-size: auto 40%, auto 30%, auto 15%;
    width: 25vw;
  }
  .survey-wrapper.skincare-followup {
    width: 75vw;
    background-image: url("../assets/skincarebg4.png");
    background-repeat: no-repeat;
    background-size: auto 30%;
    background-position: left 10% bottom 10%;
  }
  .survey-wrapper.skincare-followup:after {
    background-position: right 0 top 0;
    background-size: auto 100%;
    width: 25vw;
  }
}
</style>
