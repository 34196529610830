<template>
<div class="flex progress-bar">
<div v-if="cur<=questionLength" class="flex w-full gap-1">
    <div v-for="i in questionLength" :key="i" class="bar w-full h-1.5 rounded-sm" :class="{'current-bar':i<=cur}"></div>
 </div>
  <div v-if="cur===questionLength+1" class="flex flex-col items-end w-full relative">
    <div class="text-[16px] font-medium">100%</div>
    <div class="bar w-full h-1.5 rounded-sm current-bar"></div>
 </div>
</div>
</template>

<script>
export default {
name:"Progress Bar",
props:{
    cur:Number,
    questionLength:Number
}
}
</script>

<style>
.bar{
    background-color:rgba(242, 242, 242, 1);
}

.nutripro .current-bar{
background-color:rgba(147, 199, 52, 1);
}

.skincare .progress-bar{
border:solid 1px black;
border-radius:18px;
}

.skincare-followup .progress-bar{
border:solid 1px black;
border-radius:18px;
}

.skincare .current-bar{
    background-color:#FFD700;
}

.skincare-followup .current-bar{
    background-color:#FFD700;
}
</style>