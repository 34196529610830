<template>
<!-- for nutripro only -->
  <div class="flex flex-col items-center max-w-[500px]">
    <div class="question-text text-center mt-2 mb-7">{{ question.question }}</div>
    <div class="flex flex-wrap gap-y-4 justify-between">
      <div v-for="(label, i) in question.labels" :key="i" class="flex basis-[46%] min-w-min last:basis-full">
        <input
          type="number"
          min="0"
          :id="i"
          :name="question.question_id[i]"
          class="box-border text-center grow w-full"
          v-model="store.answers[question.question_id[i]].open_answer"
          :readonly="!store.ifEditable()"
          :data-cy="label"
        />
        <div class="text-label pl-2 w-[38px]">{{ label }}</div>
      </div>
    </div>
    <div class="flex w-full justify-between h-[48px] mt-8">
      <div
        v-for="(choice, i) in question.choices"
        :key="i"
        class="box-border text-center text-[16px] leading-[20px] py-[14px] basis-[46%] flex justify-between pl-4 pr-2 sm:pl-8 sm:pr-4"
        :class="{ 'selected-option': isSelected(i),'cursor-pointer':store.ifEditable() }"
        @click="select(i)"
        :data-cy="question.option_id[i]"
      >
        {{ choice }}
        <img v-if="isSelected(i)" :src="checkmark"  />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../service/store.js";
import checkmark from "../assets/checkmark.svg";
export default {
  name: "Mulitple Input",
  props: {
    question: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      store,checkmark
    };
  },
  methods: {
   select(i) {
      if( this.store.ifEditable())
      this.store.answers[this.question.question_id[3]].option_id=this.question.option_id[i];//hard code for gender
    },
    isSelected(i){
      return this.question.option_id[i]===this.store.answers[this.question.question_id[3]]?.option_id;//hard code for gender
    }
  },

};
</script>

<style>
.box-border {
  border: 1px solid #bcccdc;
  border-radius: 5px;
  height: 48px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.text-label {
  line-height: 48px;
  font-size: 16px;
}
</style>
