<template>
  <div>
    <div class="question-text mt-2 mb-7">{{ question.question }}</div>
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-y-6 gap-x-4 w-full items-center">
      <div v-for="(img, i) in question.uploads" :key="i" class="flex flex-col items-center gap-y-3 justify-center">
        <img v-if="!previewImages[img.value]" :src="getImgUrl(img.sample)" :class="uploading[i]?'grayscale':''"/>
        <img :src="previewImages[img.value]" />
        <svg v-if="uploading[i]" class="animate-spin h-8 w-8 text-white absolute" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <label>
          <input
            type="file"
            accept="image/png, image/jpeg"
            :aria-label="img.label"
            class="hidden"
            @change="uploadImage($event, i, question.question_id[i])"
            :disabled="!store.ifEditable()"
          />
          <span class="text-[#20A8C3] text-[16px]" :class="store.ifEditable()?'cursor-pointer underline':''">{{ img.label }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script setup>
import { store } from "../../service/storeV2.js";
import { onMounted, ref } from "vue";

const props = defineProps({
  question: Object,
});

const previewImages = ref([]);

const getImgUrl = (pic) => {
  return new URL(`../../../src/assets/${pic}`, import.meta.url).href;
};

const uploading=ref([false,false,false])

const uploadImage = async (e, idx, question_id) => {
  uploading.value[idx]=true;
  const image = e.target.files[0];
  const data = new FormData();
  data.append("file", image);
  let res = await store.uploadFile(data);
  uploading.value[idx]=false;
  if (res) {
    store.answers[props.question.question_id[idx]].open_answer=res
    previewImages.value[idx] = res;
  }
};

onMounted(()=>{
  props.question.question_id.forEach((id)=>{
    previewImages.value.push(store.answers[id].open_answer)
  })
  
})
</script>

<style></style>
