<template>
  <div class="flex flex-col">
    <div class="text-center question-number">Question {{ no }}/{{ questionLength }}</div>
    <component :is="question.type" :question="question"></component>
    <div class="mt-28 mb-6">
      <div v-if="showWarningMsg" class="text-red-500 text-center mb-2">{{ question.warningMsg }}</div>
      <div class="flex flex-wrap justify-center gap-x-12 gap-y-5">
        <button
          v-if="no < questionLength"
          class="button-style flex items-center justify-center gap-2"
          @click="next"
          :disabled="nextDisabled"
          data-cy="next"
        >
          {{ buttonText }}<img v-if="buttonText === 'Next Question'" :src="NextArrow" />
        </button>
        <el-button
          v-loading="loadingsave"
          v-if="store.ifEditable() && no > 1 && store.surveyType !== 'skincare-followup'"
          class="button-style"
          @click="save"
          :disabled="no <= 1"
          data-cy="save"
        >
          Save
        </el-button>
        <button v-if="store.ifEditable() && no === questionLength" class="button-style" @click="submit" :disabled="nextDisabled" data-cy="submitQ">
          Submit
        </button>
      </div>
      <div v-if="question.none_option" class="flex justify-center mt-6" :class="{ 'cursor-pointer': store.ifEditable() }">
        <div @click="selectAllNone" class="text-center italic font-medium leading-normal text-[14px] md:text-[16px] underline">
          {{ question.none_option }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessageBox } from "element-plus";
import SingleChoice from "./SingleChoice.vue";
import MultipleChoice from "./MultipleChoice.vue";
import NextArrow from "../assets/nextArrow.svg";
import HeightWeightInput from "./MultipleInput.vue";
import LongInput from "./LongInput.vue";
import RadioQuestion from "./RadioQuestion.vue";
import UploadImage from "./UploadImage.vue";
import FollowupQuestion from "./FollowupQuestion.vue";
import SingleSeverityChoice from "./SingleSeverityChoice.vue";
import { store } from "../service/store.js";

export default {
  name: "Question Item",
  components: {
    SingleChoice,
    MultipleChoice,
    HeightWeightInput,
    LongInput,
    UploadImage,
    FollowupQuestion,
    RadioQuestion,
    SingleSeverityChoice,
  },
  props: {
    no: {
      type: Number,
      required: true,
    },
    questionLength: {
      type: Number,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { store, loadingsave: false };
  },
  computed: {
    buttonText() {
      if (this.question.buttonText) return this.question.buttonText;
      return "Next Question";
    },
    nextDisabled() {
      return this.validateAnswers(this.question);
    },
    showWarningMsg() {
      if (!this.question.warningMsg) return false;
      return this.question.showWarningMsgCondition.some((option) => this.store.answers[this.question.question_id].includes(option));
    },
  },
  setup() {
    return { NextArrow };
  },
  methods: {
    next() {
      this.$emit("nextQuestion");
    },
    submit() {
      ElMessageBox.confirm(
        "If you submit your quiz, we would <strong>not allow</strong> you to go back and edit. Please make sure all your information are true and correct.",
        "Reminder",
        {
          customClass: `${this.store.surveyType} confirm-submit-box`,
          confirmButtonText: "Complete Submission",
          cancelButtonText: "Back to Edit",
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          return this.store.uploadData(true);
        })
        .then((res) => {
          if (res === "submitted") {
            this.$emit("successSumbit");
          }
        });
    },
    async save() {
      this.loadingsave = true;
      await this.store.uploadData(false);
      this.loadingsave = false;
    },
    validateAnswers(question) {
      //return true when there is error/should be disabled
      return this.store.validateAnswers(question, this.store.answers);
    },
    selectAllNone() {
      if (this.store.ifEditable()) {
        if (this.question.type === "MultipleChoice") {
          this.store.answers[this.question.question_id] = [this.question.none_id];
        } else if (this.question.type === "SingleChoice"||this.question.type === "RadioQuestion") {
          this.store.answers[this.question.question_id] = {question_id:this.question_id,option_id:this.question.none_id};
        } else if (this.question.type === "SingleSeverityChoice") {
          this.question.questions.forEach((q) => (this.store.answers[q.question_id] ={question_id:q.question_id,option_id:q.option_id[0]} ));
        }
        if(this.no<this.questionLength) this.next();
      }
    },
  },
  emits: {
    nextQuestion: null,
    successSumbit: null,
  },
};
</script>

<style>
.question-number {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(151, 151, 151, 1);
}

.skincare .question-number {
  display: none;
}

.skincare .question-number {
  display: none;
}

.skincare-followup .question-number {
  display: none;
}

.skincare-followup .question-number {
  display: none;
}

.el-button.button-style,
.button-style {
  width: 186px;
  height: 42px;
  color: white;
  font-size: 16px;
  line-height: 42px;
  border-radius: 6px;
}

.confirm-submit-box {
  width: 80%;
  max-width: 585px;
}

.confirm-submit-box .el-message-box__title {
  padding: 68px 0 70px 0;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.confirm-submit-box .el-message-box__message {
  padding: 0 32px 0 32px;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
}

.confirm-submit-box .el-message-box__btns {
  padding: 60px 50px 80px 50px;
  justify-content: space-between;
  gap: 20px;
}

.nutripro.confirm-submit-box .el-button.el-button--primary,
.skincare.confirm-submit-box .el-button.el-button--primary {
  color: white;
}
.skincare-followup.confirm-submit-box .el-button.el-button--primary {
  color: white;
}

.nutripro.confirm-submit-box .el-button {
  color: rgba(52, 152, 219, 1);
  border-color: rgba(52, 152, 219, 1);
}

.skincare.confirm-submit-box .el-button {
  color: black;
  border-color: black;
}
.skincare-followup.confirm-submit-box .el-button {
  color: black;
  border-color: black;
}

.nutripro .el-button.button-style,
.nutripro .button-style,
.nutripro.confirm-submit-box .el-button--primary {
  background-color: rgba(52, 152, 219, 1);
}
.nutripro .button-style:disabled {
  background: rgba(52, 152, 219, 0.5);
}

.skincare .button-style,
.skincare .el-button.button-style,
.skincare.confirm-submit-box .el-button--primary {
  background-color: #ffd700;
  border-color: #ffd700;
}

.skincare-followup .button-style,
.skincare-followup .el-button.button-style,
.skincare-followup.confirm-submit-box .el-button--primary {
  background-color: #ffd700;
  border-color: #ffd700;
}

.skincare .button-style:disabled {
  background-color: rgba(255, 215, 0, 0.5);
}
.skincare-followup .button-style:disabled {
  background-color: rgba(255, 215, 0, 0.5);
}

.skincare .confirm-submit-box .el-button {
  color: #ffd700;
  border-color: #ffd700;
}
.skincare-followup .confirm-submit-box .el-button {
  color: #ffd700;
  border-color: #ffd700;
}

.skincare.confirm-submit-box .el-message-box__headerbtn .el-message-box__close:hover {
  color: #ffd700 !important;
}
.skincar-followup.confirm-submit-box .el-message-box__headerbtn .el-message-box__close:hover {
  color: #ffd700 !important;
}
.confirm-submit-box .el-button--primary {
  background-color: rgba(52, 152, 219, 1);
  color: white;
}

.confirm-submit-box .el-message-box__btns button:nth-child(2) {
  margin-left: auto;
}

@media only screen and (max-width: 436px) {
  .el-button,
  .el-button--primary {
    margin: auto;
  }
}
</style>
