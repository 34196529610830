<template>
  <div class="min-h-screen start-page-wrapper flex flex-col" :class="surveyType==='nutripro-followup'?'nutripro':surveyType">
    <survey-header v-if="surveyType === 'skincare' || surveyType==='skincare-followup'" :surveyType="surveyType" />
    <div class="py-28 start-page" v-if="surveyType!=='skincare-followup'">
      <div v-if="surveyType === 'nutripro'"><img :src="nutripro" /></div>
        <div v-if="surveyType === 'nutripro-followup'"><img :src="nutriprofollowup" /></div>
      <p class="welcome-title">{{ title }} Test Questionnaire</p>
      <ul class="welcome-description mt-8">
        This brief questionnaire will help us better understand your {{need}} needs, so we can provide more personalized healthcare recommendations.
      </ul>
      <div class="mt-20 flex"><button class="button-style" @click="goToSurvey()">Start</button></div>
    </div>
    <div class="py-28 start-page" v-else>
      <p class="welcome-title">{{ title }} Questionnaire</p>
      <ul class="welcome-description mt-8">
        <li>This quiz helps us learn how régénère impacted your skin.</li>
        <li> Your answers help us adjust your régénère face serum formulation to suit your unique needs.</li>
      </ul>
      <div class="mt-20 flex"><button class="button-style-followup" @click="goToSurvey()">Start</button></div>
    </div>
  </div>
</template>

<script>
import SurveyHeader from "../components/SurveyHeader.vue";
import nutriprofollowup from "../assets/nutriprofollowup.svg";
import nutripro from "../assets/nutripro.png";
// import { useRouter, useRoute } from "vue-router";
// import { ElNotification } from "element-plus";
// import { useWindowSize } from "@vueuse/core";

export default {
  name: "Start Page",
  components: { SurveyHeader },
  setup() {
    //const { width } = useWindowSize();
    // const router = useRouter();
    // const route = useRoute();
    return { nutriprofollowup,nutripro };
  },
  mounted() {
    this.getText();
  },
  props: {
    surveyType: {
      type: String,
    },
  },
  data() {
    return { title: "", need: "" };
  },
  emits: ["goToSurvey"],
  methods: {
    goToSurvey() {
      this.$emit("goToSurvey");
      // if (!this.route.params.barcode  || this.route.params.barcode === ''){
      //   let position = this.width < 480 ? "bottom-left" : "top-right";
      //   ElNotification({
      //     title: "Missing Barcode",
      //     message: "",
      //     type: "error",
      //     position: position,
      //   });
      // } else {
      //   this.router.push(`/survey/${this.route.params.barcode}`);
      // }
    },
    getText() {
      switch (this.surveyType) {
        case "nutripro":
          this.title = "NutriProZ Longevity";
          this.need = "longevity";
          return;
            case "nutripro-followup":
          this.title = "NutriProZ follow-up";
          this.need = "longevity";
          return;
        case "skincare":
          this.title = "Skincare";
          this.need = "skincare";
          return
        case "skincare-followup":
          this.title = "Skincare Follow Up"
      }
    },
  },
};
</script>

<style>
.welcome-title {
  text-align: start;
  font-size: 24px;
  color: rgba(74, 74, 74, 1);
  font-weight: 700;
  line-height: 28px;
}

.welcome-description li {
  font-size: 16px;
  line-height: 19px;
  color: rgba(74, 74, 74, 1);
  margin-left: 1em;
}

li:not(:last-child) {
  margin-bottom: 18px;
}

.welcome-description li::before {
  content: "\2022";
  color: rgba(74, 74, 74, 1);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.nutripro .start-page {
  max-width: 472px;
  margin: auto;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.nutripro .start-page .button-style {
  margin: auto;
}

.nutripro .welcome-title {
  margin-top: 64px;
}

.skincare.start-page-wrapper {
  background-color: black;
  background-position: right 0 bottom 30%, right 8% bottom 5%;
  background-image: url("../assets/skincarebg1.png"), url("../assets/skincarebg2.png");
  background-repeat: no-repeat;
  background-size: auto 18%, auto 14%;
}

.skincare .start-page {
  padding-left: 8%;
  padding-right: 8%;
}

.skincare .welcome-title,
.skincare .welcome-description li,
.skincare .welcome-description li::before {
  color: white;
}

.skincare .welcome-title {
  font-size: 24px;
  line-height: 32px;
}

.skincare .welcome-description li {
  font-size: 16px;
  max-width: 800px;
  line-height: 22px;
}

.skincare-followup.start-page-wrapper {
  background-position: right 0 top 0%;
  background-color: black;
  background-image: url("../assets/skincarefollowupbg.png");
  background-repeat: no-repeat;
  background-size: auto 20%, auto 23% ;
}

.skincare-followup .start-page {
  padding-left: 8%;
  padding-right: 8%;
}

.skincare-followup .welcome-title,
.skincare-followup .welcome-description li,
.skincare-followup .welcome-description li::before {
  color: white;
}

.skincare-followup .welcome-title {
  font-size: 24px;
  line-height: 32px;
}

.skincare-followup .welcome-description li{
  font-size: 16px;
  max-width: 800px;
  line-height: 22px;
}

@media (min-width: 768px) {
  .skincare.start-page-wrapper {
    background-position: right 0 top 56%, right 8% top 92%;
    background-image: url("../assets/skincarebg1.png"), url("../assets/skincarebg2.png");
    background-repeat: no-repeat;
    background-size: auto 25%, auto 20%;
  }

  .skincare .welcome-title {
    font-size: 64px;
    line-height: 72px;
  }

  .skincare .welcome-description li {
    font-size: 24px;
    max-width: 560px;
    line-height: 28px;
  }
  .skincare-followup.start-page-wrapper {
    background-position: right 0 top 30%;
    background-image: url("../assets/skincarefollowupbg.png");
    background-repeat: no-repeat;
    background-size: auto 40%, auto 25%;
  }

  .skincare-followup .welcome-title {
    font-size: 64px;
    line-height: 72px;
  }

  .skincare-followup .welcome-description li {
    font-size: 24px;
    max-width: 560px;
    line-height: 28px;
  }
}

@media (min-width: 1280px) {
  .skincare.start-page-wrapper {
    background-position: right 0 top 30%, right 8% top 80%;
    background-size: auto 35%, auto 24%;
  }

  .skincare .start-page {
   margin:auto 0;
  }
  .skincare .welcome-description li {
    max-width: 840px;
  }

  .skincare-followup.start-page-wrapper {
    background-position: right 0 top 60%, right 8% top 80%;
    background-size: auto 70%, auto 100%;
  }

  .skincare-followup .start-page {
   margin:auto 0;
  }
  .skincare-followup .welcome-description li {
    max-width: 682px;
  }
}

.button-style-followup{
  display: flex;
width: 200px;
height: 42px;
padding: 10px 24px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 4px;
background: #FFD700;
}
</style>
