<template>
  <div class="flex flex-col lg:flex-row lg:items-center w-full">
    <div class="lg:basis-[30%] w-full mb-2 lg:mb-0 lg:text-start text-center text-[16px]">
      {{ question.question }}
    </div>
    <div class="w-full lg:basis-[70%] h-[27px] md:h-[37px] justify-center lg:justify-start relative flex items-center max-w-[800px]">
      <div v-if="this.store.answers[this.question.question_id] === this.question.option_id[0]" class="absolute sev-select-bar"></div>
      <div class="w-full h-full grid grid-cols-4 gap-x-[2px] gap-y-[2px] shrink-0 font-semibold" >
        <div
          class="sev-choice relative"
          :class="{ 'selected-choice': isSelected(i), 'cursor-pointer': store.ifEditable() }"
          v-for="i in [1, 2, 3, 4]"
          @click="select(i)"
          :key="i"
        >
          {{ question.choice[i] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../service/store.js";
export default {
  props: {
    question: {
      type: Object,
      requrie: true,
    }
  },
  methods: {
    setToNone() {
      this.store.answers[this.question.question_id].option_id = this.question.option_id[0];
    },
    isSelected(i) {
      return this.question.option_id[i] === this.store.answers[this.question.question_id].option_id;
    },
    select(i) {
      if (this.store.ifEditable()) {
        if (this.isSelected(i)) {
          this.setToNone();
        } else {
          this.store.answers[this.question.question_id].option_id = this.question.option_id[i];
        }
      }
    },
  },
  data() {
    return {
      store,
    };
  },
  mounted() {
    if(!this.store.answers[this.question.question_id]) this.setToNone();
  },
};
</script>
<style>
.sev-choice {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d9d9d9;
  font-style: normal;
  font-family: Inter;
}
.sev-choice:first-child {
  border-radius: 20px 0px 0px 20px;
}

.sev-choice:last-child {
  border-radius: 0px 20px 20px 0px;
}

.sev-choice.selected-choice {
  background-color: black;
  color: white;
}

.sev-choice.selected-choice::after {
  position: absolute;
  content: "";
  height: 118%;
  right: 0;
  width: 9px;
  border-radius: 16px;
  border: 1px solid #000;
  background: #fff;
  z-index: 999;
}

.sev-select-bar {
  height: 118%;
  left: 0;
  width: 9px;
  border-radius: 16px;
  border: 1px solid #000;
  background: #fff;
  z-index: 999;
}

@media (max-width: 1200px) {
  .sev-choice.selected-choice::after {
    width: 6.5px;
  }

  .sev-select-bar {
  width: 6.5px;
}
}
</style>
